// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"
import "./src/dark-mode-darkgrey.css"
// Highlighting for code blocks
// import "prismjs/themes/prism.css"
import Prism from "prismjs";

(typeof global !== "undefined" ? global : window).Prism = Prism;

require("prismjs/components/prism-powershell");
// from ardalis blog
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
require("prismjs/plugins/command-line/prism-command-line.css")

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
  document.documentElement.classList.add('dark');
} else {
  document.documentElement.classList.remove('dark');
}

var themeBaseUrl = "https://cdnjs.cloudflare.com/ajax/libs/prism/1.28.0/themes/";
var head = document.documentElement.getElementsByTagName("head")[0];
var prismJsTheme = document.createElement("link");
prismJsTheme.rel = "stylesheet";  
if (localStorage.getItem("theme") === "dark") {  
  prismJsTheme.href = themeBaseUrl + "prism-okaidia.css";  
} else {
  prismJsTheme.href = themeBaseUrl + "prism.css";  
}
head.appendChild(prismJsTheme);